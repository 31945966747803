import React from "react"
import styled from "styled-components"
import tw from "twin.macro"
import { FormattedMessage, Link } from "gatsby-plugin-intl"

import heroImg from "../images/hero.svg"
import heroImgMobile from "../images/hero-mobile.svg"
import heroBump from "../images/hero-bump.svg"

const Main = styled.div`
  ${tw`w-full bg-dark`}
`

const Container = styled.div`
  ${tw`px-5 relative pt-7 sm:pt-12 md:pt-32 pb-48 md:pb-24 xl:pb-8 max-w-default flex w-full`};
  margin: 0 auto;
  z-index: 1;
`

const Title = styled.h1`
  ${tw`text-40 sm:text-5xl md:text-6xl leading-snug font-semibold text-white`}
`

const Illustration = styled.img`
  width: 1000px;
  ${tw`absolute right-0 mr--12 hidden lg:block `}
  @media only screen and (max-width: 1280px) {
    width: 860px;
    margin-top: 32px;
  }
`
const IllustrationMobile = styled.img`
  width: 70%;
  max-width: 620px;
  ${tw`absolute right-0 block lg:hidden `}
  @media only screen and (max-width: 639px) {
    top: 244px;
    width: 94%;
    max-width: 440px;
  }
`

const Content = styled.div`
  ${tw`relative z-10`}
`

const CTA = styled(Link)`
  ${tw`text-dark text-lg font-semibold bg-primary px-12 py-3 rounded-full md:mt-14 mt-8 focus:outline-none hover:bg-primaryHovered duration-200`}
  display: inline-block;
`

const HeroBump = styled.img`
  ${tw`w-full`}
  margin-top: -0.5px;
`

const HomeHeroSection = () => {
  return (
    <>
      <Main>
        <Container>
          <Content>
            <Title>
              <FormattedMessage id="home.hero.international" /> <br />
              <FormattedMessage id="home.hero.recruitment" /> <br />
              <FormattedMessage id="home.hero.agency" />
            </Title>
            <CTA to="/contact">
              <FormattedMessage id="home.cta_hero" />
            </CTA>
          </Content>
          <Illustration src={heroImg} alt="open door for everyone" />
          <IllustrationMobile
            src={heroImgMobile}
            alt="open door for everyone"
          />
        </Container>
      </Main>
      <HeroBump src={heroBump} alt="bump" />
    </>
  )
}

export default HomeHeroSection
