import React, { Component } from "react"
import Slider from "react-slick"
import styled from "styled-components"
import tw from "twin.macro"
import { FormattedMessage } from "gatsby-plugin-intl"

import { SmoothVerticalScrolling } from "../utils/scroll"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import user from "../images/pavel-rohel.png"
import user1 from "../images/avatar-placeholder.png"
import user2 from "../images/kawa.png"
import arrowRight from "../images/icons/arrow-right-icon.svg"
import arrowLeft from "../images/icons/arrow-left-icon.svg"

const Wrapper = styled.div`
  ${tw`flex w-full content-between`}
`

const TestimonialsWrapper = styled.div`
  ${tw`relative w-3/5 bg-white focus:outline-none relative shadow-values mt-10 md:mt-12 mb-32 lg:ml-20 xl:ml-40 px-8 md:px-12 lg:px-20 xl:px-24 py-10 md:py-50`}
  max-width:633px;
  border-radius: 48px;
  @media only screen and (max-width: 1280px) {
    max-width: 600px;
  }
  @media only screen and (max-width: 1023px) {
    ${tw`mx-5`}
  }
`

const Navigation = styled(Slider)`
  ${tw`absolute mt-24 hidden lg:flex right-0`}
  width:210px
`
const NavImgWrapper = styled.div`
  ${tw`focus:outline-none cursor-pointer`}
  display:flex !important;
  justify-content: flex-end !important;
`

const NavImg = styled.img`
  ${tw`ml-6 xl:ml-12`}
  width:80px !important;
  height: 80px !important;
  display: inline-block !important;
`

const CurrentTestimonial = styled(Slider)`
  ${tw`relative w-4/5`}
  max-width:846px;
  @media only screen and (max-width: 1280px) {
    max-width: 730px;
  }
  @media only screen and (max-width: 1023px) {
    max-width: 100%;
    width: 100%;
  }
`

const TestimonialName = styled.p`
  ${tw`text-lg md:text-2xl font-semibold px-0 md:px-2 pb-1 lg:pb-0 text-dark`}
`
const TestimonialJob = styled.p`
  ${tw`text-base md:text-lg font-semibold px-0 md:px-2 text-navigationHover`}
`
const TestimonialText = styled.p`
  ${tw`text-base md:text-lg pt-6 md:pt-8 font-light text-dark px-0 md:px-2 leading-testimonial`}
`
const TestimonialImage = styled.img`
  ${tw`block lg:absolute w-14 md:w-20 lg:w-32 xl:w-auto`}
  left: -80px;
  top: 48px;
`
const ButtonWrapper = styled.div`
  ${tw`mt-32 hidden lg:block`}
`
const NextPadding = styled.div`
  ${tw`cursor-pointer`}
`

const Next = styled.div`
  ${tw`m-4 h-4 w-4  border-dark border-b-4 border-r-4 `}
  transform: rotate(-45deg)
`

const TestimonialHeader = styled.div`
  display: flex !important;
  align-items: center;
  justify-content: flex-start !important;
`
const TestimonialPersonalInfo = styled.div`
  ${tw`pl-5 lg:pl-0`}
`

const ArrowsWrapper = styled.div`
  ${tw` absolute self-center`}
  display: none !important;
  bottom: -100px;
  margin: 0 auto;
  z-index: 99;
  @media only screen and (max-width: 1023px) {
    display: flex !important;
    width: calc(100% - 96px);
  }
  @media only screen and (max-width: 767px) {
    display: flex !important;
    width: calc(100% - 66px);
  }
`
const ArrowRight = styled.div`
  ${tw`bg-primary flex justify-center items-center rounded-full `}
  padding: 17px 28px 17px 32px;
  margin: 5px;
`
const ArrowLeft = styled.div`
  ${tw`bg-primary flex justify-center items-center rounded-full`}
  padding: 17px 32px 17px 28px;
  margin: 5px;
`

export default class Testimonial extends Component {
  constructor(props) {
    super(props)
    this.state = {
      nav1: null,
      nav2: null,
      current: 0,
    }
    this.next = this.next.bind(this)
    this.prev = this.prev.bind(this)
    this.nextMobile = this.nextMobile.bind(this)
    this.prevMobile = this.prevMobile.bind(this)
    this.second = this.second.bind(this)
  }

  next() {
    this.state.nav1.slickNext()
  }

  prev() {
    this.state.nav1.slickPrev()
  }

  nextMobile() {
    this.state.nav1.slickNext()
    SmoothVerticalScrolling(document.getElementById("testimonials"), 400, "top")
  }

  prevMobile() {
    this.state.nav1.slickPrev()
    SmoothVerticalScrolling(document.getElementById("testimonials"), 400, "top")
  }

  second = i => {
    console.log(i)
    this.state.nav1.slickGoTo(i)
  }

  changeCurrent(current) {
    this.setState({
      current: current,
    })
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
    })
  }

  render() {
    const settings = {
      beforeChange: (current, next) => {
        this.changeCurrent(next)
      },
    }
    return (
      <Wrapper>
        <CurrentTestimonial
          className="testimonialWrapper"
          asNavFor={this.state.nav2}
          arrows={false}
          fade={true}
          draggable={false}
          ref={slider => (this.slider1 = slider)}
          {...settings}
        >
          <TestimonialsWrapper>
            <TestimonialHeader>
              <TestimonialImage src={user} />
              <TestimonialPersonalInfo>
                <TestimonialName>Pavel Rohel</TestimonialName>
                <TestimonialJob>
                  <FormattedMessage id="home.employee" /> NeueJob
                </TestimonialJob>
              </TestimonialPersonalInfo>
            </TestimonialHeader>
            <TestimonialText>
              <FormattedMessage id="home.testimonials.user_1_part_1" /> <br />
              <br />
              <FormattedMessage id="home.testimonials.user_1_part_2" />
            </TestimonialText>
            <ArrowsWrapper>
              <ArrowLeft
                style={{
                  opacity: this.state.current === 0 ? "0.3" : "1",
                }}
              >
                <img src={arrowLeft} />
              </ArrowLeft>
              <ArrowRight onClick={this.nextMobile}>
                <img src={arrowRight} />
              </ArrowRight>
            </ArrowsWrapper>
          </TestimonialsWrapper>
          <TestimonialsWrapper>
            <TestimonialHeader>
              <TestimonialImage src={user1} />
              <TestimonialPersonalInfo>
                <TestimonialName>Beytullah Kurtisi</TestimonialName>
                <TestimonialJob>
                  <FormattedMessage id="home.employee" /> NeueJob
                </TestimonialJob>
              </TestimonialPersonalInfo>
            </TestimonialHeader>
            <TestimonialText>
              <FormattedMessage id="home.testimonials.user_2_part_1" /> <br />
              <br />
              <FormattedMessage id="home.testimonials.user_2_part_2" />
            </TestimonialText>
            <ArrowsWrapper>
              <ArrowLeft
                onClick={this.prevMobile}
                style={{
                  opacity: this.state.current === 0 ? "0.3" : "1",
                }}
              >
                <img src={arrowLeft} />
              </ArrowLeft>
              <ArrowRight onClick={this.nextMobile}>
                <img src={arrowRight} />
              </ArrowRight>
            </ArrowsWrapper>
          </TestimonialsWrapper>
          <TestimonialsWrapper>
            <TestimonialHeader>
              <TestimonialImage src={user2} />
              <TestimonialPersonalInfo>
                <TestimonialName>KaWa</TestimonialName>
                <TestimonialJob>KaWa recruiter</TestimonialJob>
              </TestimonialPersonalInfo>
            </TestimonialHeader>
            <TestimonialText>
              <FormattedMessage id="home.testimonials.user_3_part_1" /> <br />
              <br />
              <FormattedMessage id="home.testimonials.user_3_part_2" />
            </TestimonialText>
            <ArrowsWrapper>
              <ArrowLeft
                onClick={this.prevMobile}
                style={{
                  opacity: this.state.current === 0 ? "0.3" : "1",
                }}
              >
                <img src={arrowLeft} />
              </ArrowLeft>
              <ArrowRight
                style={{
                  opacity: this.state.current === 2 ? "0.3" : "1",
                }}
              >
                <img src={arrowRight} />
              </ArrowRight>
            </ArrowsWrapper>
          </TestimonialsWrapper>
        </CurrentTestimonial>
        <ButtonWrapper>
          <NextPadding onClick={this.next}>
            <Next />
          </NextPadding>
        </ButtonWrapper>
        <Navigation
          asNavFor={this.state.nav1}
          ref={slider => (this.slider2 = slider)}
          fade={true}
          draggable={false}
          slidesToShow={1}
          slidesPerRow={1}
          arrows={false}
        >
          <NavImgWrapper>
            <NavImg onClick={this.next} src={user1} />
            <NavImg
              onClick={() => this.second(this.state.current - 1)}
              src={user2}
            />
          </NavImgWrapper>
          <NavImgWrapper>
            <NavImg onClick={this.next} src={user2} />
            <NavImg
              onClick={() => this.second(this.state.current - 1)}
              src={user}
            />
          </NavImgWrapper>
          <NavImgWrapper>
            <NavImg onClick={this.next} src={user} />
            <NavImg
              onClick={() => this.second(this.state.current - 1)}
              src={user1}
            />
          </NavImgWrapper>
        </Navigation>
      </Wrapper>
    )
  }
}
