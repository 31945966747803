import React from "react"
import styled from "styled-components"
import tw from "twin.macro"
import { FormattedMessage } from "gatsby-plugin-intl"

import ellipse from "../images/ellipse-big.svg"
import recIcon from "../images/icons/recruitment-icon.svg"
import recIconSmall from "../images/icons/recruitment-icon-small.svg"
import trainIcon from "../images/icons/training-icon.svg"
import trainIconSmall from "../images/icons/training-icon-small.svg"
import helpIcon from "../images/icons/help-icon.svg"
import helpIconSmall from "../images/icons/help-icon-small.svg"
import adminIcon from "../images/icons/administration-icon.svg"
import adminIconSmall from "../images/icons/administration-icon-small.svg"

import OurFocusMobile from "./ourFocusMobile"

const Main = styled.div`
  ${tw`w-full relative`}
`

const Container = styled.div`
  ${tw`px-5 relative max-w-default flex flex-col w-full`};
  margin: 140px auto 0;
  @media only screen and (max-width: 1023px) {
    margin: 80px auto 0;
  }
  @media only screen and (max-width: 767px) {
    margin: 51px auto 0;
  }
`
const Text = styled.div``

const Title = styled.h1`
  ${tw`text-4xl md:text-5xl leading-snug  font-semibold text-dark`}
`

const Paragraph = styled.p`
  ${tw`text-base md:text-lg text-dark pt-8 md:pt-12 font-light leading-testimonial`}
  max-width:920px;
`

const Ellipse = styled.img`
  ${tw`absolute right-0 top-0 hidden md:block`}
  width:200px;
  z-index: -1;
`

const OurFocusWrapper = styled.div`
  ${tw` items-center justify-center hidden md:flex`}
  margin-top:76px
`

const InnerCircle = styled.div`
  ${tw`flex border font-light relative border-2 border-gray-200 items-center justify-center rounded-full`}
  width:500px;
  height: 500px;
`

const Recruitment = styled.div`
  ${tw`absolute flex justify-center items-center rounded-full cursor-pointer hover:shadow-valuesHover shadow-values h-33 w-33 left-0 top-0`}
  transition-duration: 300ms;
`
const Training = styled.div`
  ${tw`absolute flex justify-center items-center rounded-full cursor-pointer hover:shadow-valuesHover shadow-values h-33 w-33 right-0 top-0`}
  transition-duration: 300ms;
`
const Help = styled.div`
  ${tw`absolute flex justify-center items-center rounded-full cursor-pointer hover:shadow-valuesHover shadow-values h-33 w-33 left-0 bottom-0`}
  transition-duration: 300ms;
`

const Administration = styled.div`
  ${tw`absolute flex justify-center items-center rounded-full cursor-pointer hover:shadow-valuesHover shadow-values h-33 w-33 right-0 bottom-0`}
  transition-duration: 300ms;
`

const RecruitmentText = styled.p`
  ${tw` absolute text-right text-xl font-semibold text-navigation`}
  width: 130px;
  top: 10px;
  left: -150px;
`
const TrainingText = styled.p`
  ${tw` absolute text-left text-xl font-semibold text-navigation`}
  width: 130px;
  top: 10px;
  right: -150px;
`
const HelpText = styled.p`
  ${tw` absolute text-right text-xl font-semibold text-navigation`}
  width: 130px;
  bottom: 10px;
  left: -150px;
`
const AdministrationText = styled.p`
  ${tw` absolute text-left text-xl font-semibold text-navigation`}
  width: 130px;
  bottom: 10px;
  right: -150px;
`
const ValueIcon = styled.img`
  ${tw`absolute`}
`

const InnerText = styled.p`
  ${tw`text-center text-lg`}
  max-width:348px;
`

class HomeOurFocus extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      val: "recruitment",
    }

    this.passValue = this.passValue.bind(this)
  }

  passValue = val => {
    this.setState({
      val,
    })
  }

  render() {
    return (
      <Main>
        <Container>
          <Text>
            <Title>
              <FormattedMessage id="home.you_are_important" />
            </Title>
            <Paragraph>
              <FormattedMessage id="home.who_are_we.normal_1" />
              <b>
                {" "}
                <FormattedMessage id="home.who_are_we.bold_1" />
              </b>
              <FormattedMessage id="home.who_are_we.normal_2" />
              <b>
                {" "}
                <FormattedMessage id="home.who_are_we.bold_2" />
              </b>
            </Paragraph>
          </Text>
          <OurFocusWrapper>
            <InnerCircle>
              <Recruitment
                onClick={() => this.passValue("recruitment")}
                style={{
                  backgroundImage:
                    this.state.val === "recruitment"
                      ? `radial-gradient(farthest-corner at 40px 40px,
                  #B8FFF0 0%, #41FAD4 100%)`
                      : `linear-gradient(white, white)`,
                  height: this.state.val === "recruitment" ? "180px" : "136px",
                  width: this.state.val === "recruitment" ? "180px" : "136px",
                  marginLeft:
                    this.state.val === "recruitment" ? "-13px" : "9px",
                  marginTop: this.state.val === "recruitment" ? "-13px" : "9px",
                }}
              >
                <ValueIcon
                  src={recIcon}
                  style={{
                    width: this.state.val === "recruitment" ? "74px" : "56px",
                    opacity: this.state.val === "recruitment" ? "1" : "0",
                    transitionDuration:
                      this.state.val === "recruitment" ? "400ms" : "0ms",
                  }}
                />
                <ValueIcon
                  src={recIconSmall}
                  style={{
                    width: this.state.val === "recruitment" ? "74px" : "56px",
                    opacity: this.state.val === "recruitment" ? "0" : "1",
                    transitionDuration:
                      this.state.val === "recruitment" ? "0ms" : "400ms",
                  }}
                />
                <RecruitmentText
                  style={{
                    color:
                      this.state.val === "recruitment" ? "#000919" : "#B3B5BA",
                  }}
                >
                  {" "}
                  <FormattedMessage id="home.recruitment" />{" "}
                </RecruitmentText>
              </Recruitment>

              <Training
                onClick={() => this.passValue("training")}
                style={{
                  backgroundImage:
                    this.state.val === "training"
                      ? `radial-gradient(farthest-corner at 40px 40px,
                #B8FFF0 0%, #41FAD4 100%)`
                      : `linear-gradient(white, white)`,
                  height: this.state.val === "training" ? "180px" : "136px",
                  width: this.state.val === "training" ? "180px" : "136px",
                  marginRight: this.state.val === "training" ? "-13px" : "9px",
                  marginTop: this.state.val === "training" ? "-13px" : "9px",
                }}
              >
                <ValueIcon
                  src={trainIcon}
                  style={{
                    width: this.state.val === "training" ? "74px" : "56px",
                    opacity: this.state.val === "training" ? "1" : "0",
                    transitionDuration:
                      this.state.val === "training" ? "400ms" : "0ms",
                  }}
                />
                <ValueIcon
                  src={trainIconSmall}
                  style={{
                    width: this.state.val === "training " ? "74px" : "56px",
                    opacity: this.state.val === "training" ? "0" : "1",
                    transitionDuration:
                      this.state.val === "training" ? "0ms" : "400ms",
                  }}
                />
                <TrainingText
                  style={{
                    color:
                      this.state.val === "training" ? "#000919" : "#B3B5BA",
                  }}
                >
                  {" "}
                  <FormattedMessage id="home.training" />{" "}
                </TrainingText>
              </Training>

              <Help
                onClick={() => this.passValue("help")}
                style={{
                  backgroundImage:
                    this.state.val === "help"
                      ? `radial-gradient(farthest-corner at 40px 40px,
                #B8FFF0 0%, #41FAD4 100%)`
                      : `linear-gradient(white, white)`,
                  height: this.state.val === "help" ? "180px" : "136px",
                  width: this.state.val === "help" ? "180px" : "136px",
                  marginLeft: this.state.val === "help" ? "-13px" : "9px",
                  marginBottom: this.state.val === "help" ? "-13px" : "9px",
                }}
              >
                <ValueIcon
                  src={helpIcon}
                  style={{
                    width: this.state.val === "help" ? "74px" : "56px",
                    opacity: this.state.val === "help" ? "1" : "0",
                    transitionDuration:
                      this.state.val === "help" ? "400ms" : "0ms",
                  }}
                />
                <ValueIcon
                  src={helpIconSmall}
                  style={{
                    width: this.state.val === "help" ? "74px" : "56px",
                    opacity: this.state.val === "help" ? "0" : "1",
                    transitionDuration:
                      this.state.val === "help" ? "0ms" : "400ms",
                  }}
                />
                <HelpText
                  style={{
                    color: this.state.val === "help" ? "#000919" : "#B3B5BA",
                  }}
                >
                  {" "}
                  <FormattedMessage id="home.help" />{" "}
                </HelpText>
              </Help>

              <Administration
                onClick={() => this.passValue("administration")}
                style={{
                  backgroundImage:
                    this.state.val === "administration"
                      ? `radial-gradient(farthest-corner at 40px 40px,
                #B8FFF0 0%, #41FAD4 100%)`
                      : `linear-gradient(white, white)`,
                  height:
                    this.state.val === "administration" ? "180px" : "136px",
                  width:
                    this.state.val === "administration" ? "180px" : "136px",
                  marginRight:
                    this.state.val === "administration" ? "-13px" : "9px",
                  marginBottom:
                    this.state.val === "administration" ? "-13px" : "9px",
                }}
              >
                <ValueIcon
                  src={adminIcon}
                  style={{
                    width:
                      this.state.val === "administration" ? "74px" : "56px",
                    height:
                      this.state.val === "administration" ? "74px" : "56px",
                    opacity: this.state.val === "administration" ? "1" : "0",
                    transitionDuration:
                      this.state.val === "administration" ? "400ms" : "0ms",
                  }}
                />
                <ValueIcon
                  src={adminIconSmall}
                  style={{
                    width:
                      this.state.val === "administration" ? "74px" : "56px",
                    height:
                      this.state.val === "administration" ? "74px" : "56px",
                    opacity: this.state.val === "administration" ? "0" : "1",
                    transitionDuration:
                      this.state.val === "administration" ? "0ms" : "400ms",
                  }}
                />
                <AdministrationText
                  style={{
                    color:
                      this.state.val === "administration"
                        ? "#000919"
                        : "#B3B5BA",
                  }}
                >
                  {" "}
                  <FormattedMessage id="home.administration" />{" "}
                </AdministrationText>
              </Administration>

              <InnerText>
                <FormattedMessage id={`home.values_text.${this.state.val}`} />
                <br />
                <b>
                  <FormattedMessage
                    id={`home.values_text1.${this.state.val}`}
                  />
                </b>
                <br />
                <FormattedMessage id={`home.values_text2.${this.state.val}`} />
              </InnerText>
            </InnerCircle>
          </OurFocusWrapper>
          <OurFocusMobile />
        </Container>
        <Ellipse src={ellipse} alt="ellipse" />
      </Main>
    )
  }
}

export default HomeOurFocus
