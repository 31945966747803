import React from "react"
import styled from "styled-components"
import tw from "twin.macro"
import { FormattedMessage } from "gatsby-plugin-intl"
import Testimonials from "./testimonials"

import quotes from "../images/quotes.svg"
import ellipse from "../images/ellipse-small.svg"

const Main = styled.div`
  ${tw`relative `}
`

const Quotes = styled.img`
  ${tw`hidden lg:block absolute top-0 `}
  right:290px;
  z-index: -1;
`

const Ellipse = styled.img`
  ${tw`hidden md:block absolute left-0 top-0`}
  width:148px;
  z-index: -1;
`

const Container = styled.div`
  ${tw`md:px-5 pb-12 relative max-w-default w-full`};
  margin: 150px auto 0;
  @media only screen and (max-width: 1023px) {
    margin: 80px auto 0;
  }
  @media only screen and (max-width: 767px) {
    margin: 0px auto 0;
  }
`

const Title = styled.h1`
  ${tw`text-4xl md:text-5xl pt-8 lg:pl-24 xl:pl-48 px-5 md:px-0 leading-snug text-center lg:text-left font-semibold text-dark`}
`

const Content = styled.div`
  ${tw`relative flex flex-col`}
`

const HomeClients = () => {
  return (
    <Main>
      <Container>
        <Content>
          <Quotes src={quotes}></Quotes>
          <Title id="testimonials">
            <FormattedMessage id="home.testimonials.title" />
          </Title>
          <Testimonials />
        </Content>
      </Container>
      <Ellipse src={ellipse} />
    </Main>
  )
}

export default HomeClients
