import React from "react"
import { useIntl } from "gatsby-plugin-intl"

import Layout from "../components/layout"
import Head from "../components/head"
import HeroSection from "../components/homeHeroSection"
import OperateAt from "../components/homeOperateAt"
import OurFocus from "../components/homeOurFocus"
import Testimonials from "../components/homeTestimonials"

const IndexPage = () => {
  const intl = useIntl()
  return (
    <Layout>
      <Head lang={intl.locale} />
      <HeroSection></HeroSection>
      <OperateAt></OperateAt>
      <OurFocus></OurFocus>
      {/* <Clients></Clients> */}
      <Testimonials></Testimonials>
    </Layout>
  )
}

export default IndexPage
