import React, { Component } from "react"
import styled from "styled-components"
import tw from "twin.macro"
import Slider from "react-slick"
import { FormattedMessage } from "gatsby-plugin-intl"

import recIconSmall from "../images/icons/recruitment-icon-small.svg"
import trainIconSmall from "../images/icons/training-icon-small.svg"
import helpIconSmall from "../images/icons/help-icon-small.svg"
import adminIconSmall from "../images/icons/administration-icon-small.svg"

const OurFocusMobileWrapper = styled.div`
  ${tw` block md:hidden mx--5 py-10`}
`
const ValuesWrapper = styled.div`
  ${tw` relative flex justify-center items-center pt-12 pb-16`}
  display: flex !important;
  outline: none !important;
`
const ValueWrapper = styled.div`
  ${tw`flex justify-center items-center`}
  width: 360px;
  @media only screen and (max-width: 695px) {
    width: 312px;
  }
  @media only screen and (max-width: 600px) {
    width: 260px;
  }
  @media only screen and (max-width: 500px) {
    width: 220px;
  }
  @media only screen and (max-width: 400px) {
    width: 212px;
  }
  @media only screen and (max-width: 350px) {
    width: 190px;
  }
`
const Value = styled.div`
  ${tw`rounded-full flex justify-center items-center shadow-values duration-700`}
  width: 164px;
  height: 164px;
`
const ValueIcon = styled.img`
  ${tw`absolute`}
`

const LineRight = styled.div`
  ${tw`absolute`}
  right: -120px;
  height: 2px;
  z-index: -1;
  width: 240px;
  background-color: #e3e4e6;
  @media only screen and (max-width: 400px) {
    right: -80px;
    width: 160px;
  }
`

const SliderContent = styled.div`
  ${tw`flex justify-center`}
`
const SliderIndex = styled.p`
  ${tw`text-center`}
`
const SliderCurrent = styled.span`
  ${tw`text-dark font-semibold text-lg`}
`
const SliderRemaining = styled.span`
  ${tw`text-lg font-semibold`}
  color: #D1D1D1;
`
const SliderTitle = styled.p`
  ${tw`text-center text-dark font-semibold pt-4 text-lg`}
`
const SliderDescriptionLight = styled.p`
  ${tw`text-center text-dark font-light pt-3 px-5`}
`
const SliderDescriptionLast = styled.p`
  ${tw`text-center text-dark font-light px-5`}
`
const SliderDescriptionBold = styled.p`
  ${tw`text-center text-dark font-semibold px-5`}
`

class OurFocusMobile extends Component {
  constructor(props) {
    super(props)
    this.state = {
      nav1: null,
      nav2: null,
      currentSlide: 0,
    }
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
    })
  }

  render() {
    const settingsSlider1 = {
      arrows: false,
      dots: false,
      infinite: false,
      speed: 700,
      slidesToShow: 1,
      slidesToScroll: 1,
      swipeToSlide: true,
      focusOnSelect: true,
      fade: true,
    }

    const settingsSlider2 = {
      arrows: false,
      dots: false,
      infinite: false,
      centerMode: true,
      speed: 700,
      slidesToShow: 1,
      slidesToScroll: 1,
      swipeToSlide: true,
      focusOnSelect: true,
      variableWidth: true,
      afterChange: current => {
        // this.setState({ currentSlide: current })
        // console.log(current)
      },
      beforeChange: (current, next) => {
        this.setState({ currentSlide: next })
        // console.log("before next" + next)
        // console.log("before current" + current)
      },
    }

    return (
      <OurFocusMobileWrapper>
        <Slider
          asNavFor={this.state.nav2}
          ref={slider => (this.slider1 = slider)}
          {...settingsSlider1}
        >
          <SliderContent>
            <SliderIndex>
              <SliderCurrent>1 </SliderCurrent>
              <SliderRemaining>/ 4</SliderRemaining>
            </SliderIndex>
            <SliderTitle>
              <FormattedMessage id={`home.recruitment`} />
            </SliderTitle>

            <SliderDescriptionLight>
              <FormattedMessage id={`home.values_text.recruitment`} />
            </SliderDescriptionLight>
            <SliderDescriptionBold>
              {" "}
              <FormattedMessage id={`home.values_text1.recruitment`} />
            </SliderDescriptionBold>
          </SliderContent>

          <SliderContent>
            <SliderIndex>
              <SliderCurrent>2 </SliderCurrent>
              <SliderRemaining>/ 4</SliderRemaining>
            </SliderIndex>
            <SliderTitle>
              <FormattedMessage id={`home.training`} />
            </SliderTitle>

            <SliderDescriptionLight>
              <FormattedMessage id={`home.values_text.training`} />
            </SliderDescriptionLight>
            <SliderDescriptionBold>
              <FormattedMessage id={`home.values_text1.training`} />
            </SliderDescriptionBold>
            <SliderDescriptionLast>
              <FormattedMessage id={`home.values_text2.training`} />
            </SliderDescriptionLast>
          </SliderContent>

          <SliderContent>
            <SliderIndex>
              <SliderCurrent>3 </SliderCurrent>
              <SliderRemaining>/ 4</SliderRemaining>
            </SliderIndex>
            <SliderTitle>
              <FormattedMessage id={`home.administration`} />
            </SliderTitle>

            <SliderDescriptionLight>
              <FormattedMessage id={`home.values_text.administration`} />
            </SliderDescriptionLight>
            <SliderDescriptionBold>
              <FormattedMessage id={`home.values_text1.administration`} />
            </SliderDescriptionBold>
            <SliderDescriptionLast>
              <FormattedMessage id={`home.values_text2.administration`} />
            </SliderDescriptionLast>
          </SliderContent>

          <SliderContent>
            <SliderIndex>
              <SliderCurrent>4 </SliderCurrent>
              <SliderRemaining>/ 4</SliderRemaining>
            </SliderIndex>
            <SliderTitle>
              <FormattedMessage id={`home.help`} />
            </SliderTitle>

            <SliderDescriptionLight>
              <FormattedMessage id={`home.values_text.help`} />
            </SliderDescriptionLight>
            <SliderDescriptionBold>
              <FormattedMessage id={`home.values_text1.help`} />
            </SliderDescriptionBold>
          </SliderContent>
        </Slider>
        <Slider
          asNavFor={this.state.nav1}
          ref={slider => (this.slider2 = slider)}
          {...settingsSlider2}
        >
          <ValuesWrapper>
            <ValueWrapper>
              <Value
                style={{
                  backgroundImage:
                    this.state.currentSlide === 0
                      ? "radial-gradient(farthest-corner at 40px 40px, #B8FFF0 0%, #41FAD4 100%)"
                      : "radial-gradient(#fff, #fff)",
                  transform:
                    this.state.currentSlide === 0 ? "scale(1)" : "scale(0.74)",
                }}
              >
                <ValueIcon width="64px" height="64px" src={recIconSmall} />
              </Value>
              <LineRight />
            </ValueWrapper>
          </ValuesWrapper>

          <ValuesWrapper>
            <ValueWrapper>
              <Value
                style={{
                  backgroundImage:
                    this.state.currentSlide === 1
                      ? "radial-gradient(farthest-corner at 40px 40px, #B8FFF0 0%, #41FAD4 100%)"
                      : "radial-gradient(#fff, #fff)",
                  transform:
                    this.state.currentSlide === 1 ? "scale(1)" : "scale(0.74)",
                }}
              >
                <ValueIcon width="64px" height="64px" src={trainIconSmall} />
              </Value>
              <LineRight />
            </ValueWrapper>
          </ValuesWrapper>

          <ValuesWrapper>
            <ValueWrapper>
              <Value
                style={{
                  backgroundImage:
                    this.state.currentSlide === 2
                      ? "radial-gradient(farthest-corner at 40px 40px, #B8FFF0 0%, #41FAD4 100%)"
                      : "radial-gradient(#fff, #fff)",
                  transform:
                    this.state.currentSlide === 2 ? "scale(1)" : "scale(0.74)",
                }}
              >
                <ValueIcon width="64px" height="64px" src={helpIconSmall} />
              </Value>
              <LineRight />
            </ValueWrapper>
          </ValuesWrapper>

          <ValuesWrapper>
            <ValueWrapper>
              <Value
                style={{
                  backgroundImage:
                    this.state.currentSlide === 3
                      ? "radial-gradient(farthest-corner at 40px 40px, #B8FFF0 0%, #41FAD4 100%)"
                      : "radial-gradient(#fff, #fff)",
                  transform:
                    this.state.currentSlide === 3 ? "scale(1)" : "scale(0.74)",
                }}
              >
                <ValueIcon width="64px" height="64px" src={adminIconSmall} />
              </Value>
            </ValueWrapper>
          </ValuesWrapper>
        </Slider>
      </OurFocusMobileWrapper>
    )
  }
}

export default OurFocusMobile
